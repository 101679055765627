* {
  outline: none !important;
}

@media only screen and (max-width: 992px) {
  .clicked {
    display: none;
  }
  #state:target {
    display: block;
  }
}
